// images for about
import g from '../img/about/g.jpg';
import Prithvi_Raj from '../img/about/Prithvi_Raj.jpeg';
import Tanmay_Biyani from '../img/about/Tanmay_Biyani.jpg';
import Aravind_DOP from '../img/about/Aravind_DOP.jpg';
import Aswin from '../img/about/Aswin.jpg';
import Tiara from '../img/about/Tiara.jpg';
import Dhruv_Kashyap from '../img/about/Dhruv_Kashyap.jpg';

// media for home page mp4
import bgVideoAsaya from '../media/home/Asaya.mp4';
import bgVideoReverie from '../media/home/Reverie.mp4';
import bgVideoShecommerz from '../media/home/Shecommerz.mp4'
import bgVideoNagwa from '../media/home/Nagwa.mp4';
import bgVideoSuaveMundane from '../media/home/suave.mp4'

// media for home page webm
import bgVideoAsayaWebm from '../media/home/Asaya.webm';
import bgVideoReverieWebm from '../media/home/Reverie.webm';
import bgVideoShecommerzWebm from '../media/home/Shecommerz.webm'
import bgVideoNagwaWebm from '../media/home/Nagwa.webm';
import bgVideoSuaveMundaneWebm from '../media/home/suave.webm'

// media client logos


// media shorts mp4
import vid from '../media/Video-95.mp4'
import Simba from '../media/Simba.mp4';
import bgTribe_of_6 from '../media/Tribe_of_6.mp4';
import deme from '../media/deme.mp4';
import Mokodora from '../media/mokodora.mp4'
import FormOfStyle from '../media/FormOfStyle.mp4'
import NishaXAsaya from '../media/NishaXAsaya.mp4'
import SpyK from '../media/SpyK.mp4';
import Keif from '../media/Keif.mp4';
import Nail_Station from '../media/Nail_Station.mp4'
import Bhavani from '../media/Bhavani.mp4';
import Dhahia from '../media/Dhahia.mp4';
import nothing from '../media/nothing.mp4'
import Arnav_Personal from '../media/Arnav_Personal.mp4'
import Acqua from '../media/Acqua.mp4'
import Mello from '../media/Mello.mp4'


import vegnonveg_poster from '../media/poster/reels/vegnonveg.jpg'
import Simba_poster from '../media/poster/reels/Simba.jpg';
import bgTribe_of_6_poster from '../media/poster/reels/bgTribe_of_6.jpg';
import deme_poster from '../media/poster/reels/deme.jpg';
import Mokodora_poster from '../media/poster/reels/Mokodora.jpg'
import FormOfStyle_poster from '../media/poster/reels/FormOfStyle.jpg'
import NishaXAsaya_poster from '../media/poster/reels/NishaXAsaya.jpg'
import SpyK_poster from '../media/poster/reels/SpyK.jpg';
import Keif_poster from '../media/poster/reels/Keif.jpg';
import Nail_Station_poster from '../media/poster/reels/Nail_Station.jpg'
import Bhavani_poster from '../media/poster/reels/Bhavani.jpg';
import Dhahia_poster from '../media/poster/reels/Dhahia.jpg';
import nothing_poster from '../media/poster/reels/nothing.jpg'
import Arnav_Personal_poster from '../media/poster/reels/Arnav_Personal.jpg'
import Acqua_poster from '../media/poster/reels/Acqua.jpg'
import Mello_poster from '../media/poster/reels/Mello.jpg'


// media shorts webm
import vid_webm from '../media/Video-95.webm'
import Simba_webm from '../media/Simba.webm';
import bgTribe_of_6_Webm from '../media/Tribe_of_6.webm';
import deme_webm from '../media/deme.webm';
import Mokodora_webm from '../media/mokodora.webm'
import FormOfStyle_webm from '../media/FormOfStyle.webm'
import NishaXAsaya_webm from '../media/NishaXAsaya.webm'
import SpyK_webm from '../media/SpyK.webm';
import Keif_webm from '../media/Keif.webm';
import Nail_Station_webm from '../media/Nail_Station.webm'
import Bhavani_webm from '../media/Bhavani.webm';
import Dhahia_webm from '../media/Dhahia.webm';
import nothing_webm from '../media/nothing.webm'
import Arnav_Personal_webm from '../media/Arnav_Personal.webm'
import Acqua_webm from '../media/Acqua.webm'
import Mello_webm from '../media/Mello.webm'

import Shecommerz from '../media/home/Shecommerz.mp4'

export const paths = {
    vegnonveg: vid,
    Simba: Simba,
    bgTribe_of_6: bgTribe_of_6,
    deme: deme,
    Mokodora: Mokodora,
    FormOfStyle: FormOfStyle,
    NishaXAsaya: NishaXAsaya,
    SpyK: SpyK,
    Keif: Keif,
    Nail_Station: Nail_Station,
    Bhavani: Bhavani,
    Dhahia: Dhahia,
    nothing: nothing,
    Arnav_Personal: Arnav_Personal,
    Acqua: Acqua,
    Mello: Mello,
    Shecommerz: Shecommerz,

    vegnonveg_webm: vid_webm,
    Simba_webm: Simba_webm,
    bgTribe_of_6_Webm: bgTribe_of_6_Webm,
    deme_webm: deme_webm,
    Mokodora_webm: Mokodora_webm,
    FormOfStyle_webm: FormOfStyle_webm,
    NishaXAsaya_webm: NishaXAsaya_webm,
    SpyK_webm: SpyK_webm,
    Keif_webm: Keif_webm,
    Nail_Station_webm: Nail_Station_webm,
    Bhavani_webm: Bhavani_webm,
    Dhahia_webm: Dhahia_webm,
    nothing_webm: nothing_webm,
    Arnav_Personal_webm: Arnav_Personal_webm,
    Acqua_webm: Acqua_webm,
    Mello_webm: Mello_webm,

    vegnonveg_poster: vegnonveg_poster,
    bgTribe_of_6_poster: bgTribe_of_6_poster,
    deme_poster:deme_poster,
    FormOfStyle_poster: FormOfStyle_poster,
    Mokodora_poster: Mokodora_poster,
    NishaXAsaya_poster: NishaXAsaya_poster,
    Simba_poster: Simba_poster,
    SpyK_poster: SpyK_poster,
    Keif_poster: Keif_poster,
    Nail_Station_poster: Nail_Station_poster,
    Bhavani_poster: Bhavani_poster,
    Dhahia_poster: Dhahia_poster,
    nothing_poster: nothing_poster,
    Arnav_Personal_poster: Arnav_Personal_poster,
    Acqua_poster: Acqua_poster,
    Mello_poster: Mello_poster

};

export const collaboratorImages = {
    g: g,
    Prithvi_Raj: Prithvi_Raj,
    Tanmay_Biyani: Tanmay_Biyani,
    Aravind_DOP: Aravind_DOP,
    Tiara: Tiara,
    Aswin: Aswin,
    Dhruv_Kashyap: Dhruv_Kashyap
};

export const homeMedia = {
    bgVideoAsaya: bgVideoAsaya,
    bgVideoNagwa: bgVideoNagwa,
    bgVideoReverie: bgVideoReverie,
    bgVideoSuaveMundane: bgVideoSuaveMundane,
    bgVideoShecommerz: bgVideoShecommerz,
    bgVideoAsayaWebm: bgVideoAsayaWebm,
    bgVideoNagwaWebm: bgVideoNagwaWebm,
    bgVideoReverieWebm: bgVideoReverieWebm,
    bgVideoSuaveMundaneWebm: bgVideoSuaveMundaneWebm,
    bgVideoShecommerzWebm: bgVideoShecommerzWebm
};