import React, { useState, useMemo, useEffect, useRef } from 'react';
// import ReactPlayer from 'react-player'
import shecommercePoster from '../media/poster/shecommerce.webp';
import Asaya from '../media/poster/Asaya.webp';
import NagwaPoster from '../media/poster/Nagwa.webp';
import ReveriePoster from '../media/poster/Reverie.webp';
import suavePoster from '../media/poster/suave.webp';
import { homeMedia } from '../components/MediaPaths';

const VideoRender = React.lazy(()=> import('../components/VideoRender'));


const Home = ({onSectionRef}) => {
  const homeSection = useRef(null);
  const [videoLinks] = useState([
    {
      videoData: {
        mp4: homeMedia.bgVideoAsaya,
        webm: homeMedia.bgVideoAsayaWebm,
      },
      title: 'Asaya - Welcome Home',
      redirectLink: 'https://vimeo.com/856639345',
      fileName: 'Asaya.mp4',
      poster: Asaya
    },
    {
      videoData: {
        mp4: homeMedia.bgVideoReverie,
        webm: homeMedia.bgVideoReverieWebm
      },
      title: 'REVERIE',
      redirectLink: 'https://vimeo.com/730222592',
      fileName: 'Reverie.mp4',
      poster: ReveriePoster
    },
    {
      videoData: {
        mp4: homeMedia.bgVideoShecommerz,
        webm: homeMedia.bgVideoShecommerzWebm,
      },
      title: 'Shecommerz',
      redirectLink: 'https://vimeo.com/938484704',
      fileName: 'Shecommerz.mp4',
      poster: shecommercePoster
    },
    {
      videoData: {
        mp4: homeMedia.bgVideoNagwa,
        webm: homeMedia.bgVideoNagwaWebm,
      },
      title: 'Nagwa x Hopo',
      redirectLink: 'https://vimeo.com/857532688',
      fileName: 'Nagwa.mp4',
      poster: NagwaPoster
    },
    {
      videoData: {
        mp4: homeMedia.bgVideoSuaveMundane,
        webm: homeMedia.bgVideoSuaveMundaneWebm,
      },
      title: 'Suave Mundane',
      redirectLink: 'https://vimeo.com/857528166',
      fileName: 'suave.mp4',
      poster: suavePoster
    }
  ])

  const [bgVideoIndex, setBgVideoIndex] = useState(0);
  const playerRefs = useRef(videoLinks.map(() => React.createRef()));
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    console.log(distance)
    if (isLeftSwipe || isRightSwipe) alert('swipe', isLeftSwipe ? 'left' : 'right')
    // add your conditional logic here
  }

  function setBgVideoIndexStart(index){
    setBgVideoIndex(index);
    if (playerRefs.current[index].current) {
      playerRefs.current[index].current.seekTo(0);
    }
  }

  useEffect(() => {
    if (onSectionRef && typeof onSectionRef === 'function') {
      onSectionRef(homeSection.current);
    }
  }, [onSectionRef]);
  return (
   <section ref={homeSection} className='section bg-cover h-auto' id='home'>
    <div className='landing'>
      <div className='h-[full] relative' onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <VideoRender data={videoLinks} videoIndex={bgVideoIndex} playerRef={playerRefs}/>
        <div className='video-nav-mobile xl:hidden'>
          {
            videoLinks.map((videoLink, index) => (
              <a key={index} onClick={() => setBgVideoIndexStart(index)} className='navbar-collection-item' target='_blank'>
                <div className='navlink_button w-inline-block'>
                  <div className={`${bgVideoIndex === index ? "text-white" : ""} ${'bulletpoint'}`}>•</div>
                </div>
              </a>
            ))
          }
        </div>
        <div className='div-block-starting-page w-container'>
          {
            videoLinks.map((videoLink, index) => (
              <a key={index} onMouseEnter={() => setBgVideoIndexStart(index)} className='link-block w-inline-block' href={videoLink.redirectLink} target='_blank'>
                <div className='text-starting font-Namata hover:font-NamataItalic'>
                  {videoLink.title}
                </div>
              </a>
            ))
          }
        </div>
        </div>
      
    </div>
      
    </section>
  );
};

export default Home;
