import React from 'react';
import { FaVimeo , FaInstagram , FaLinkedin  } from 'react-icons/fa';

const Socials = () => {
  return <>
   {/* Socials */}
    <a href='https://www.instagram.com/gautammonangi.films/' target='_blank'>
      <FaInstagram/>
    </a>
    <a href='https://www.youtube.com/@4gauti' target='_blank'>
      <FaLinkedin />
    </a>
    <a href='https://vimeo.com/gautammonangi' target='_blank'>
      <FaVimeo />
    </a>
  {/* </motion.div> */}
  </>
};

export default Socials;
