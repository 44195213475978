import React, { useEffect, useRef } from 'react';
// import components
import Logo from '../img/header/headerLogo.png'
import blackLogo from '../img/header/logo.png'
import MobileNav from './MobileNav';

// import Link
import { Link } from 'react-scroll';

const DefaultHeader = ({isHeaderChange}) => {
  const preloadedImage = useRef();

useEffect(() => {
    preloadedImage.current = new Image();
    preloadedImage.current.src = Logo;
}, []);
  return (
  <header className={`${isHeaderChange ? "bg-black h-[100px]" : "h-[100px]"} ${'fixed w-full px-[30px] lg:px-[100px] z-30 flex items-center'}`}>
    <div className='flex flex-col lg:flex-row items-center w-full justify-between'>
      {/* nav - initially hidden - show on desktop mode */}
      <nav className='hidden lg:flex gap-x-12 font-semibold'>
        <Link to='home'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30} className={`${isHeaderChange ? "hover:text-[#ffffff]" : "text-[#ffffff]"} ${'cursor-pointer text-[22px] hover:text-primary transition'}`}>
          Home
        </Link>
        <Link to='shorts'
              activeClass='active'
              smooth={true}
              spy={true} className={`${isHeaderChange ? "hover:text-[#ffffff]" : "text-[#ffffff]"} ${'cursor-pointer text-[22px] hover:text-primary transition'}`}>
          Shorts
        </Link>
        <Link to='work'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30} className={`${isHeaderChange ? "hover:text-[#ffffff]" : "text-[#ffffff]"} ${'cursor-pointer text-[22px] hover:text-primary transition'}`}>
          Work
        </Link>
        <Link to='about'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30}  className={`${isHeaderChange ? "hover:text-[#ffffff]" : "text-[#ffffff]"} ${'cursor-pointer text-[22px] hover:text-primary transition'}`}>
          About
        </Link>
        <Link to='contact'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-20}  className={`${isHeaderChange ? "hover:text-[#ffffff]" : "text-[#ffffff]"} ${'cursor-pointer text-[22px] hover:text-primary transition'}`}>
          Contact
        </Link>
      </nav>
      {/* logo */}
      <a href='#' className={`${'max-w-[170px]'}`}>
        <img src={Logo} alt='gautammonangifilms logo'/>
      </a>
      {/* <a href='#' className={`${isHeaderChange ? "" : "video-bg"} ${'max-w-[50px]'}`}>
        <img src={blackLogo} alt='gautammonangifilms logo' loading='lazy'/>
      </a> */}
    </div>
     <MobileNav isHeaderChange={isHeaderChange}/>
  </header>
  );
};

export default DefaultHeader;
