import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';



const Community = ({collaborators}) => {
  return <>
    <motion.h1 
        variants={fadeIn('up', 0.3)} initial="hidden"
        whileInView={'show'} viewport={{once: true, amount: 0.5}}
        className='h2'>From our Community</motion.h1>
        <motion.div
        variants={fadeIn('right', 0.3)} initial="hidden"
        whileInView={'show'} viewport={{once: true, amount: 0.5}}
        className='flex flex-col gap-y-4 max-w-[54rem] text-start'>
            {
            collaborators.map((collaboratorRow,index ) => (
                <div key={collaboratorRow.rowID} className='flex gap-x-10'>
                {
                    collaboratorRow.rowData.map((collaborator,index ) => (
                    <div key={index} className='flex-row flex w-[50%] gap-x-2'>
                        {/* <div  className={'w-[90px] '+collaborator.style}> */}
                        <div className='aboutImageGroup'>
                        <img className='aboutImage' src={collaborator.image} loading='lazy'/>
                        </div>
                        <a href={collaborator.link} target='_blank' className='w-[inherit] flex flex-col justify-center'>
                        <div className='collaborator-name'>
                            {collaborator.name}
                        </div>
                        <div className='text-[12px]'>
                            {collaborator.role}
                        </div>
                        </a>
                    </div>
                    ))
                }
                </div>
            ))
            }
    </motion.div>
  </>
};

export default Community;
